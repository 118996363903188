<template>
  <div class="my-3 mx-4">
    <div class="row">
      <div class="col-12">
        <div class="heading">Reservation Check-Out</div>
        <p class="text-muted">
          Check out a specific reservation. No additional details about the
          reservation can be updated at this time.
        </p>
        <AdminReservationSummary :reservation="reservation" />
        <AdminReservationContact
          header="Customer"
          :contact="reservation.customerContact"
        />
        <AdminReservationComments :reservationId="+reservationId" />
        <AdminReservationHistory :reservationId="+reservationId" />
        <ValidationObserver
          ref="checkOutForm"
          tag="form"
          id="checkOutForm"
          @submit.prevent="onSubmit"
        >
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-2 mb-0">Check-Out Details</h4>
            </div>
            <div class="card-body">
              <FormErrorAlert
                v-if="errors.length > 0"
                :errors="errors"
                :formRef="this.$refs.checkOutForm"
              />
              <DateInput
                vid="startDate"
                :rules="
                  `required|isDate|betweenDates:'${this.reservation.checkedInDate}','${this.reservation.departureDate}'`
                "
                name="Check-Out Date"
                id="checkedOutDate"
                :dateDisabledFn="maxCheckOut"
                :initialDate="initialDate"
                v-model="checkedOutDate"
                class="mb-3"
                :immediate="true"
              />
            </div>
          </div>
          <button class="btn btn-success mr-2">
            <i class="fas fa-calendar-times"></i> CHECK OUT
          </button>
          <button class="btn btn-primary" type="button" @click="cancel">
            CANCEL
          </button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationSummary from "@/components/admin/AdminReservationSummary.vue";
import AdminReservationComments from "@/components/admin/AdminReservationComments.vue";
import AdminReservationHistory from "@/components/admin/AdminReservationHistory.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import { ValidationObserver } from "vee-validate";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "AdminReservationCheckOut",
  title: "Admin - Reservation Check-Out",
  components: {
    AdminReservationContact,
    AdminReservationSummary,
    AdminReservationComments,
    AdminReservationHistory,
    ValidationObserver,
    DateInput,
    FormErrorAlert
  },
  props: {
    reservationId: String
  },
  data() {
    return {
      reservation: {},
      checkedOutDate: "",
      errors: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    initialDate() {
      return this.reservation.departureDate
        ? moment(this.reservation.departureDate, "MM-DD-YYYY").format(
            "YYYY-MM-DD"
          )
        : "";
    }
  },
  methods: {
    onSubmit() {
      this.$refs.checkOutForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.checkOutForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.checkOutForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          try {
            this.$store.commit("auth/setLoading", true);
            this.errors = [];
            const spotReservationService = new AdminSpotReservationService(
              this.tenantId
            );
            const response = await spotReservationService.checkOut({
              reservationId: this.reservationId,
              checkedOutDate: this.checkedOutDate
            });
            if (response.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Reservation successfully checked out",
                layer: "admin"
              });
              this.cancel();
            } else {
              window.scrollTo(0, 0);
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (e) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async getReservationDetail() {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        const response = await spotReservationService.getReservationDetail(
          this.reservationId
        );
        this.reservation = response.data;
        this.checkedOutDate = moment(
          this.reservation.departureDate,
          "MM/DD/YYYY"
        ).format("L");
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    cancel() {
      window.scrollTo(0, 0);
      this.$router.go(-1);
    },
    maxCheckOut(ymd) {
      if (
        moment(this.reservation.departureDate, "MM/DD/YYYY").isSameOrBefore(
          moment(),
          "days"
        )
      ) {
        return !moment(ymd).isBetween(
          moment(this.reservation.checkedInDate, "MM/DD/YYYY"),
          moment(this.reservation.departureDate, "MM/DD/YYYY"),
          "days",
          "[]"
        );
      } else {
        return !moment(ymd).isBetween(
          moment(this.reservation.checkedInDate, "MM/DD/YYYY"),
          moment(),
          "days",
          "[]"
        );
      }
    }
  },
  created() {
    this.getReservationDetail();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
</style>
